import { various } from "config";
import useData from "hooks/useData";
import Layout from "components/general/Layout";
import { formatDate, isCurrent } from "utils";
import NoData from "components/general/NoData";

export default function Various(): JSX.Element {
    const { data: allData } = useData(various, "Todos");
    const rawData = allData as any;

    const data =
        rawData?.map((item: any) => ({
            fecha: item.c[0]?.f || "",
            encargadoAV: item.c[2]?.v || "",
            auxiliarAV: item.c[3]?.v || "",
            microfonoZoom: item.c[4]?.v || "",
            acomodador1: item.c[5]?.v || "",
            acomodador2: item.c[6]?.v || "",
            microfono1: item.c[7]?.v || "",
            microfono2: item.c[8]?.v || "",
            plataforma: item.c[9]?.v || "",
            sonido: item.c[10]?.v || "",
            estacionamiento: item.c[11]?.v || "",
            paseMovilidad: item.c[12]?.v || "",
            documento: item.c[13]?.v || "",
            registro: item.c[14]?.v || "",
        })) || [];

    const currentData = data.filter((item: any) => isCurrent(item.fecha));

    return (
        <Layout title="Privilegios varios">
            {!currentData.length && <NoData />}
            {currentData.map(
                (item: any, index: number) =>
                    (item.encargadoAV ||
                        item.auxiliarAV ||
                        item.microfonoZoom ||
                        item.acomodador1 ||
                        item.acomodador2 ||
                        item.estacionamiento ||
                        item.microfono2 ||
                        item.microfono2 ||
                        item.plataforma ||
                        item.paseMovilidad ||
                        item.sonido ||
                        item.documento ||
                        item.registro) && (
                        <div className="block-container" key={index}>
                            <div className="date">{formatDate(item.fecha)}</div>

                            <div>
                                {(item.encargadoAV ||
                                    item.auxiliarAV ||
                                    item.microfonoZoom) && (
                                    <>
                                        <div className="titulo">
                                            audio / video
                                        </div>
                                        <div style={{ marginLeft: "20px" }}>
                                            {item.encargadoAV && (
                                                <>
                                                    <div className="titulo">
                                                        Encargado
                                                    </div>
                                                    <div
                                                        className="president"
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        {item.encargadoAV}
                                                    </div>
                                                </>
                                            )}

                                            {item.auxiliarAV && (
                                                <>
                                                    <div className="titulo">
                                                        Auxiliar
                                                    </div>
                                                    <div
                                                        className="president"
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        {item.auxiliarAV}
                                                    </div>
                                                </>
                                            )}

                                            {(item.microfono1 ||
                                                item.microfono2) && (
                                                <>
                                                    <div className="titulo">
                                                        Micrófonos
                                                    </div>
                                                    <div
                                                        className="president"
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >{`${item.microfono1} / ${item.microfono2}`}</div>
                                                </>
                                            )}

                                            {item.plataforma && (
                                                <>
                                                    <div className="titulo">
                                                        Plataforma
                                                    </div>
                                                    <div
                                                        className="president"
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        {item.plataforma}
                                                    </div>
                                                </>
                                            )}

                                            {item.microfonoZoom && (
                                                <>
                                                    <div className="titulo">
                                                        Micrófono
                                                    </div>
                                                    <div
                                                        className="president"
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        {item.microfonoZoom}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                )}

                                {item.acomodador1 || item.acomodador2 ? (
                                    <div
                                        className="titulo"
                                        style={{ marginTop: "40px" }}
                                    >
                                        Acomodadores
                                    </div>
                                ) : null}
                                <div style={{ marginLeft: "20px" }}>
                                    {item.acomodador1 && item.acomodador2 && (
                                        <div
                                            className="president"
                                            style={{ marginLeft: "10px" }}
                                        >
                                            {`${item.acomodador1} / ${item.acomodador2}`}
                                        </div>
                                    )}

                                    {item.sonido && (
                                        <>
                                            <div className="titulo">Sonido</div>
                                            <div
                                                className="president"
                                                style={{ marginLeft: "10px" }}
                                            >
                                                {item.sonido}
                                            </div>
                                        </>
                                    )}
                                    {item.estacionamiento && (
                                        <>
                                            <div className="titulo">
                                                Estacionamiento
                                            </div>
                                            <div
                                                className="president"
                                                style={{ marginLeft: "10px" }}
                                            >
                                                {item.estacionamiento}
                                            </div>
                                        </>
                                    )}

                                    {item.paseMovilidad && (
                                        <>
                                            <div className="titulo">
                                                Responsable pase movilidad
                                            </div>
                                            <div
                                                className="president"
                                                style={{ marginLeft: "10px" }}
                                            >
                                                {item.paseMovilidad}
                                            </div>
                                        </>
                                    )}

                                    {item.documento && (
                                        <>
                                            <div className="titulo">
                                                Documento
                                            </div>
                                            <div
                                                className="president"
                                                style={{ marginLeft: "10px" }}
                                            >
                                                {item.documento}
                                            </div>
                                        </>
                                    )}

                                    {item.registro && (
                                        <>
                                            <div className="titulo">
                                                Registro
                                            </div>
                                            <div
                                                className="president"
                                                style={{ marginLeft: "10px" }}
                                            >
                                                {item.registro}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
            )}
        </Layout>
    );
}
